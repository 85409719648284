import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactMapGL from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"

const Map = () => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          mapboxApiKey
        }
      }
    }
  `)

  const [viewport, setViewport] = useState({
    width: 600,
    height: 600,
    latitude: 43.80932,
    longitude: -72.15966,
    zoom: 14,
  })

  return (
    <div style={{ width: "600px" }} className="md:ml-auto max-w-full">
      <ReactMapGL
        {...viewport}
        onViewportChange={setViewport}
        mapStyle="mapbox://styles/akievet/ckbv78oiy16rd1ipa757ld6yq"
        mapboxApiAccessToken={site.siteMetadata.mapboxApiKey}
        className="md:ml-auto"
      />
    </div>
  )
}

export default Map
